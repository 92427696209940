<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">

        <div class="row">
            <div class="col-12">
                <validated-input label="Bank Name*" v-model="model.client_name"
                                 :rules="{required: true, max:20}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <file-input class="c-file-input" label="Icon" v-model="model.icon" :disabled="loading"/>
                <image-info :width="330" :height="130"/>
            </div>
            <div class="col-12">
                <validated-input label="Order" type="number" v-model="model.order"
                             :rules="{required: false, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
                     loading-text="Saving..." :disabled="loading"/>
            </div>
        </div>

    </b-form>
</template>

<script>
import urls from '@/data/cms-urls';

export default {
    name : 'AddFeaturedBank',

    data () {
        return {
            addUrl : urls.cms.featured_bank.addEdit
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Featured Bank..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
