<template>
    <div class="card bs-5 mb-5">

        <div class="fl-te-c pb-3">
            <h4>FEATURED BANKS</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.clientAddModal.show()" text="Add"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-3">
                <validated-input label="Search" v-model="ext_search"/>
            </div>
        </div>

        <custom-vue-table :show-search-box="false" :fields="fields" :url="listUrl" :extra-params="{ext_search}"
                          class="c-cms-table d-sm-none-lg-block" :per-page="10" ref="table">

            <template #icon="{rowData}">
                <div class="container-image fl-x-cc" @click="viewFile(rowData.icon)">
                    <img :src="rowData.icon" alt="Avatar" class="c-image cursor-pointer">
                    <div class="c-overlay">
                        <div class="c-icon ml-3">
                            <i class="fa fa-eye"></i>
                        </div>
                    </div>
                </div>
            </template>

            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn icon="fa fa-eye" text="View" @click="viewDetails(rowData.id)"/>

                    <delete-btn design="basic" @click="setDelete(rowData)"/>
                </div>
            </template>

        </custom-vue-table>
        <client-mobile-view :ext_search="ext_search" class="d-lg-none" @viewDetails="viewDetails"
                            @setDelete="setDelete" ref="clientMobileView"></client-mobile-view>

        <modal title="Add Featured Bank" ref="clientAddModal" width="60r" :no-close-on-backdrop="true"
               header-color="primary">
            <AddClient @success="formSuccess"></AddClient>
        </modal>

        <modal title="Edit Featured Bank" ref="clientEditModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <EditClient :initial-data="editingItem" @success="formSuccess"></EditClient>
        </modal>

        <delete-modal ref="clientDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete <b v-html="deletingItem && deletingItem.client_name"></b>. Are you
                sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.client_name"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import AddClient from './AddClient';
import EditClient from './EditClient';
import ClientMobileView from '@/views/cms/home/client/ClientMobileView';

export default {
    name : 'FeaturedBanks',

    components : { ClientMobileView, AddClient, EditClient },

    data () {
        return {
            ext_search : '',
            listUrl    : urls.cms.featured_bank.list,
            deleteUrl  : urls.cms.featured_bank.delete,

            editingItem  : null,
            deletingItem : null,

            fields : [
                {
                    name      : 'order',
                    sortField : 'order',
                    title     : 'Order'
                },
                {
                    name  : '__slot:icon',
                    title : 'Icon'
                },
                {
                    name      : 'client_name',
                    sortField : '',
                    title     : 'Bank Name'
                },
                {
                    name      : 'created_at',
                    sortField : 'created_at',
                    title     : 'Created At'
                },
                {
                    name  : '__slot:is_enabled',
                    title : 'Enabled'
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center bg-gray aligned text-right',
                    dataClass  : 'center aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewFile (file) {
            window.open(file);
        },
        viewDetails (id) {
            this.$router.push('/cms/featured-banks/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.clientAddModal.close();
            refs.clientEditModal.close();
            refs.table.refreshTable();
            refs.clientMobileView.loadData();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.clientEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.clientDeleteModal.show();
        },

        deleteComplete (response) {
            if (response.error === true) {
                if (response.message) {
                    this.$notify(response.message, 'Failed', { type : 'danger' });
                } else {
                    this.$notify('Failed to delete, Please try again later', 'Failed',
                        { type : 'danger' }
                    );
                }
            } else {
                this.$notify('Successfully Deleted!', 'Success', { type : 'success' }
                );
            }
            this.deletingItem = null;
            this.$refs.clientDeleteModal.close();
            this.$refs.table.refreshTable();
            this.$refs.clientMobileView.loadData();
        }

    }
};
</script>
